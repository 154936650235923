import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import * as _redux from './redux'
import store, { persistor } from './redux/store'
import App from './app/App'
import { MetronicSplashScreenProvider } from './components/MetronicSplashScreen'
// import reportWebVitals from './reportWebVitals';
const { PUBLIC_URL } = process.env
_redux.setupAxios(axios, store)

ReactDOM.render(
	<MetronicSplashScreenProvider>
		<App store={store} persistor={persistor} basename={PUBLIC_URL} />
	</MetronicSplashScreenProvider>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
