import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
// import { businessSlice } from '../app/modules/Business/_redux/businessSlice'
// import { cartSlice } from '../app/modules/Cart/_redux/cartSlice'
// import { courseSlice } from '../app/modules/Courses/_redux/courseSlice'
// import { profileSlice } from '../app/modules/Profiles/_redux/profileSlice'
// import { programSlice } from '../app/modules/Programs/_redux/programSlice'
// import { settingsSlice } from '../app/modules/Settings/_redux/settingsSlice'
// import { enrollmentSlice } from '../app/modules/MyLibrary/_redux/EnrollmentSlice'
import { pagesSlice } from '../app/modules/MyPages/_redux/pagesSlice'
// import { landingSlice } from '../app/modules/Landing/_redux/landingSlice'
// import { subscriptionsSlice } from '../app/modules/Profiles/_redux/subscriptions/subscriptionsSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	// cart: cartSlice.reducer,
	// courses: courseSlice.reducer,
	// profiles: profileSlice.reducer,
	// programs: programSlice.reducer,
	// settings: settingsSlice.reducer,
	// business: businessSlice.reducer,
	// enrollments: enrollmentSlice.reducer,
	pages: pagesSlice.reducer,
	// landing: landingSlice.reducer,
	// subscriptions: subscriptionsSlice.reducer,
	// business: subscriptionsSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
