import { createSlice } from '@reduxjs/toolkit'

const initialPagesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	pageForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const pagesSlice = createSlice({
	name: 'pages',
	initialState: initialPagesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getPageById
		pageFetched: (state, action) => {
			state.actionsLoading = false
			state.pageForEdit = action.payload.pageForEdit
			state.error = null
		},
		// findPages
		pagesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		loadMore: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			const newArray = state.entities.concat(entities)
			state.entities = newArray
			state.totalCount = totalCount
			state.error = null
		},
		// createPage
		pageCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updatePage
		pageUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.page.id) {
					return action.payload.page
				}
				return entity
			})
		},
		// deletePage
		pageDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deletePages
		pagesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// pagesUpdateState
		pagesStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
		// pagesUpdateState
		attended: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { id, data } = action.payload
			console.log(id)
			state.entities = state.entities.map((entity) => {
				console.log(entity.id)
				if (Number(id) === entity.id) {
					entity = {
						...entity,
						link: data.link,
						link_details: data.link_details,
						status: true,
					}
					console.log(entity)
				}
				return entity
			})
		},
		// pageUpdateState
		pageStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { status, index } = action.payload
			state.entities[index].status = status
		},
	},
})
